button:disabled {
  opacity: 0.6;
  cursor: wait !important;
}

button:disabled > * {
  cursor: wait !important;
}

.button {
  color: black;
  background-color: #fafafa;
  border-color: #282f30;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  border-style: solid;
  border-width: 1px;
  margin: 0;
  padding: 0.4em 0.8em;
  font-size: 1rem;
  @apply shadow-md;
  @apply rounded-md;
}

.button > svg {
  font-size: 1.4rem;
  margin-right: 0.3em;
}
