.menu {
  @apply shadow-lg;
}

.list-item,
.menu > a {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  border-style: solid;
  border-width: 1px;
  margin: 0;
  padding: 0.6em 0.8em;
  font-size: 1rem;
  white-space: nowrap;
  min-width: 14em;
}

.list-item:not(:last-child),
.menu > a:not(:last-child) {
  border-bottom: 0px none;
}

.list-item svg,
.menu > a svg {
  font-size: 1.4rem;
  margin-right: 0.3em;
}

.menu > a:hover {
  background-color: #282f30;
  filter: brightness(200%);
  color: #fafafa;
  cursor: pointer;
}
