body {
  margin: 0;
  padding: 0;
  font-size: 12pt;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;

  width: 100vw;
  height: calc(100vh - var(--vh-offset));
  overflow: hidden;
  background-color: #282f30;
  color: lightgray;
  border: 0;
  border-color: black;
}

#root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(./assets/listItem.css);
@import url(./assets/buttons.css);
@import url(./assets/abcRioButton.css);

.top-full {
  top: "100%";
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mr-child-2 * {
  margin-right: 0.5rem /* 8px */;
}
.mr-child-4 * {
  margin-right: 1rem /* 16px */;
}
